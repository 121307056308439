<template>
  <div class="container notice-detail">
    <h2 class="notice-detail-title">{{ title }}</h2>
    <div class="notice-detail-context" v-html="context"></div>
  </div>
</template>

<script>
import {getInfoData} from "@/api/notice";

export default {
  name: "NoticeDetail",
  data() {
    return {
      title: '',
      context: ''
    }
  },
  mounted() {
    this.getInfo()
  },
  methods: {
    getInfo() {
      const id = this.$route.params.id;
      if (id == null) {
        return
      }
      getInfoData({'id': id}).then(data => {
        this.title = data.noticeTitle;
        this.context = data.noticeContent;
      })
    }
  }
}
</script>

<style scoped>
@import "../assets/less/noticeDetail.less";
</style>